import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { createInvoiceEntry } from "../../services/provider/invoice-service";
import schemeService from "../../services/scheme/scheme-service";
import ClaimService from '../../services/provider/claims-service';

function InvoiceForm(props) {
    const [validated, setValidated] = useState(false);
    const [invNumber, setInvNumber] = useState();
    const [invDate, setInvDate] = useState();
    const [amount, setAmount] = useState(0);
    const [discountType, setDiscountType] = useState("Fixed");
    const [discount, setDiscount] = useState(0);
    const [scheme, setScheme] = useState();
    const [invMonth, setInvMonth] = useState();
    const [invYear, setInvYear] = useState();
    const [schemes, setSchemes] = useState([]);
    const [months, setMonths] = useState([]);
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const monthsOfYear = ["January","Febuary","March","April", "May","June","July","August","September","October","November","December"]

    useEffect(() => {
        fetchSchemes();
        generateMonthPeriods();
    },[])

    const fetchSchemes = (params={}) => {
        setLoading(true);
        ClaimService.getMonthlyClaimsByScheme(params).then(resp => {
            setSchemes(resp.data.detail);
        });
        setLoading(false);
    }

    const generateMonthPeriods = () =>{
        var dt = new Date();
        console.log("getMonth() : " + dt.getMonth());
        let myMonths = []
        for(let i=1; i <=4; i++){
            const dateCopy = new Date();
            dateCopy.setMonth(dateCopy.getMonth() - i);
            console.log(dateCopy.getFullYear() + '-' + dateCopy.getMonth())
            myMonths.push(dateCopy.getFullYear() + '-' + dateCopy.getMonth())
        }
        setMonths(myMonths);

    }
    const generateUniqueId = () => {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    };

    const handleDateChange = (month, year) =>{
        console.log(invMonth)
        fetchSchemes({
            year:year,
            month:month
        }) 
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let month = invYear + '-' + invMonth;
        console.log(month)
        let payload = {
          guid:generateUniqueId(),
          scheme,
          inv_no:invNumber,
          inv_date:invDate,
          amount:amount,
          discount_type:discountType,
          discount,
          inv_month:month
        }
        await createInvoiceEntry(payload).then(resp => {
            history.push(`/invoices/${resp.data.id}`);
        }).catch(err => {
            console.log(err);
        });
    }
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Invoice Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
            {loading && <center><Spinner animation="border" /></center>}
          <Form validated={validated} onSubmit={handleSubmit}>
            <Row className="gx-3 gy-3">
            <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Invoice Month"
                                        >
                                            <Form.Select
                                                aria-label="select Invoice Month"
                                                value={invMonth}
                                                onChange={(e) =>{
                                                    setInvMonth(
                                                        e.target.value
                                                    )
                                                    handleDateChange(e.target.value, invYear)
                                                }
                                                    
                                                }
                                                required
                                            >
                                                <option></option>
                                                <option value={new Date().getMonth() + 1}>{monthsOfYear[new Date().getMonth()]}</option>
                                                <option value="01">January</option>
                                                <option value="02">Febuary</option>
                                                <option value="03">March</option>
                                                <option value="04">April</option>
                                                <option value="05">May</option>
                                                <option value="06">June</option>
                                                <option value="07">July</option>
                                                <option value="08">August</option>
                                                <option value="09">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>
                                            </Form.Select>
                                           
                                        </FloatingLabel>
                                    </Col>
               
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Invoice Year"
                                        >
                                            <Form.Select
                                                aria-label="select Invoice Year"
                                                value={invYear}
                                                onChange={(e) =>{
                                                    setInvYear(
                                                        e.target.value
                                                    )
                                                    handleDateChange(invMonth, e.target.value)
                                                }
                                                }
                                                required
                                            >
                                                <option></option>
                                                <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                                <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                                                <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                                            </Form.Select>
                                           
                                        </FloatingLabel>
                                    </Col>
            <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Scheme"
                                        >
                                            <Form.Select
                                                aria-label="select Scheme"
                                                value={scheme}
                                                onChange={(e) =>
                                                    setScheme(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                            <option></option>
                                            {schemes.map((item, i) => (
                                                <option value={item.subscheme__scheme} key={i}>{item.subscheme__scheme__name} ({item.total})</option>
                                            ))}
                                                
                                            </Form.Select>
                                           
                                        </FloatingLabel>
                                    </Col>

            <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Invoice Number"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Invoice Number"
                                                value={invNumber}
                                                onChange={(e) =>
                                                    setInvNumber(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>
                          <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Invoice Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                placeholder="Invoice Date"
                                                value={invDate}
                                                onChange={(e) =>
                                                    setInvDate(
                                                        e.target.value
                                                    )
                                                }
                                            
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>

                                    

                                    

                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Discount Type"
                                        >
                                            <Form.Select
                                                aria-label="select Discount Type"
                                                value={discountType}
                                                onChange={(e) =>
                                                    setDiscountType(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                
                                                <option value="Fixed">No Discount</option>
                                                <option value="Rate">Percent</option>
                                            </Form.Select>
                                           
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Discount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Discount"
                                                value={discount}
                                                onChange={(e) =>
                                                    setDiscount(
                                                        e.target.value
                                                    )
                                                }
                                            readOnly={discountType =='Fixed'}
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Invoice Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Invoice Amount"
                                                value={amount}
                                                onChange={(e) =>
                                                    setAmount(
                                                        e.target.value
                                                    )
                                                }
                                            readOnly
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>


                                    <Col md="12" className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="lg"
                                        >
                                            Continue
                                        </Button>
                                    </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InvoiceForm;
