import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

function RateWidget({rate, label}) {
  return (
    <>
      <Card className="mb-2">
        <Card.Body>
          <Row>
            <Col md={5}>
              <h4>{rate}</h4>
            </Col>
            <Col md={7}>
              <h5>{label}</h5>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default RateWidget